//base imports and third party
import React from 'react';
import trueFireLogo from '../../assets/img/TrueFire_logo_black.png';

export default class TrueFireContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  handleTrueFireRedirect = () => {
    window.open('https://play.truefire.com/vidami', '_blank');
  };

  render() {
    return (
      <div
        className="col-md-6 text-center mb-4"
        onClick={this.handleTrueFireRedirect}
      >
        <div
          className="device-details software-device-container"
          style={{ height: 'auto' }}
        >
          <div className="truefire-container">
            <img
              src={trueFireLogo}
              alt={'Vidami Logo'}
              className="extension-device-style"
              style={{ margin: '20px' }}
            />
            <div>
              TrueFire, the leader in online video giutar lessons, has partnered
              with us to provide an extended free trial of All Access to 80,000+
              video lessons to all Vidami customers! To claim your 90-day free
              trial and get started with Vidami + TrueFire,{' '}
              <b style={{ color: '#cc5b24' }}>
                click here and use code "TFVD90AA"
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
